import c from '@/styles/MostRead';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { withNonHTMLChildren } from '@/utils/withNonHTMLChildren';
import { withSafeInnerHTML } from '@/utils/withSafeInnerHTML';

export interface MostReadSlideDescriptionProps extends ComponentProps<'p'> {
  colors?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
}

export const MostReadSlideDescriptionComponent: Component<MostReadSlideDescriptionProps> = ({
  children,
  className,
  colors,
  variant,
  size,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const colorClassName = getPropStyles('slide_description_colors', colors, 'default');
  const variantClassName = getPropStyles('slide_description_variant', variant, 'default');
  const sizeClassName = getPropStyles('slide_description_size', size, 'default');

  const componentClassName = cn(colorClassName, variantClassName, sizeClassName, className);

  return (
    <p className={componentClassName} {...withSafeInnerHTML(children)} {...props}>
      {withNonHTMLChildren(children)}
    </p>
  );
};
