import c from '@/styles/MostRead';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { withNonHTMLChildren } from '@/utils/withNonHTMLChildren';
import { withSafeInnerHTML } from '@/utils/withSafeInnerHTML';

export interface MostReadSlideImageProps extends ComponentProps<'section'> {
  variant?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'primary'>;
}

export const MostReadSlideImageComponent: Component<MostReadSlideImageProps> = ({
  className,
  variant,
  size,
  colors,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('slide_image_variant', variant, 'default');
  const colorsClassName = getPropStyles('slide_image_colors', colors, 'primary');
  const sizeClassName = getPropStyles('slide_image_size', size, 'default');

  const componentClassName = cn(colorsClassName, variantClassName, sizeClassName, className);

  return (
    <section className={componentClassName} {...withSafeInnerHTML(children)} {...props}>
      {withNonHTMLChildren(children)}
    </section>
  );
};
