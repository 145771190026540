import c from '@/styles/MostRead';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { withNonHTMLChildren } from '@/utils/withNonHTMLChildren';
import { withSafeInnerHTML } from '@/utils/withSafeInnerHTML';

export interface MostReadHeadlineProps extends ComponentProps<'header'> {
  colors?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default' | 'decorated'>;
  size?: ClassNameProp<'default'>;
}

export const MostReadHeadlineComponent: Component<MostReadHeadlineProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const colorClassName = getPropStyles('headline_colors', colors, 'default');
  const variantClassName = getPropStyles('headline_variant', variant, 'default');
  const sizeClassName = getPropStyles('headline_size', size, 'default');

  const componentClassName = cn('flex', 'grow', colorClassName, variantClassName, sizeClassName, className);

  return (
    <header className={componentClassName} {...withSafeInnerHTML(children)} {...props}>
      {withNonHTMLChildren(children)}
    </header>
  );
};
