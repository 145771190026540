import c from '@/styles/MostRead';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { withNonHTMLChildren } from '@/utils/withNonHTMLChildren';
import { withSafeInnerHTML } from '@/utils/withSafeInnerHTML';
import { MostReadHeadlineComponent } from './MostRead.Headline';
import { MostReadSlideComponent } from './MostRead.Slide';
import { MostReadSlideCaptionComponent } from './MostRead.Slide.Caption';
import { MostReadSlideDescriptionComponent } from './MostRead.Slide.Description';
import { MostReadSlideGroupComponent } from './MostRead.Slide.Group';
import { MostReadSlideImageComponent } from './MostRead.Slide.Image';

export interface MostReadProps extends ComponentProps<'section'> {
  colors?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
}

const MostReadComponent: Component<MostReadProps> = ({ children, className, colors, size, variant, ...props }) => {
  const getPropStyles = getPropStylesFactory(c);

  const colorsClassName = getPropStyles('colors', colors, 'default');
  const variantClassName = getPropStyles('variant', variant, 'default');
  const sizeClassName = getPropStyles('size', size, 'default');

  const componentClassName = cn(colorsClassName, variantClassName, sizeClassName, className);

  return (
    <section className={componentClassName} {...withSafeInnerHTML(children)} {...props}>
      {withNonHTMLChildren(children)}
    </section>
  );
};

export const MostRead = Object.assign(MostReadComponent, {
  Headline: MostReadHeadlineComponent,
  Slide: Object.assign(MostReadSlideComponent, {
    Caption: MostReadSlideCaptionComponent,
    Description: MostReadSlideDescriptionComponent,
    Group: MostReadSlideGroupComponent,
    Image: MostReadSlideImageComponent,
  }),
});
